export const ar = {
  error: {
    unknown: 'حدث خطأ. يُرجى المحاولة مرة أخرى. إذا استمرت المشكلة، يُرجى التواصل مع الدعم.',
    errMsg: {
      DONE: 'حدث خطأ. قم بتحديث الصفحة أو اتصل بالدعم إذا استمرت المشكلة.',
      RETRY: 'حدث خطأ.',
      TOKEN_LIMIT: 'تم الوصول إلى الحد الأقصى للرموز.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'تسجيل الدخول للدردشة مع هذا الفيديو',
    },

    videoCountButton: {
      noVideosLeft: 'لا توجد مقاطع فيديو متبقية؛ عد في الساعة 12 صباحًا بتوقيت المحيط الهادئ غدًا.',
      noFreeVideosLeft:
        'لا توجد مقاطع فيديو مجانية متبقية؛ عد في الساعة 12 صباحًا بتوقيت المحيط الهادئ غدًا.',
      videosRemainingToday: 'المقاطع المتبقية اليوم:',
      upgradePlan: '(انقر لترقية خطتك)',
      getMore: '(انقر للحصول على المزيد)',
    },

    historyButton: {
      title: 'سجل الفيديو',
    },

    supportButton: {
      title: 'الدعم',
    },

    settingsButton: {
      title: 'الإعدادات',
    },

    action: {
      slowNetwork:
        'عذرًا! يبدو أن شبكتك بطيئة. يُرجى التأكد من وجود اتصال مستقر وسريع وتحديث الصفحة للتحقق مما إذا استمرت المشكلة.',
      noVideosLeft: 'لا توجد مقاطع فيديو متبقية...',
      noFreeVideosLeft: 'لا توجد مقاطع فيديو مجانية متبقية...',
      btn: 'تلخيص والدردشة',
      comeback: 'عد في الساعة 12 صباحًا بتوقيت المحيط الهادئ غدًا.',
      upgrade: 'أو قم بالترقية الآن للوصول الفوري إلى مقاطع فيديو يومية غير محدودة 🚀',
      process: 'معالجة هذا الفيديو',
      expectLengthy: 'توقع وقت انتظار طويل.',
      average: 'متوسط وقت الانتظار (بالدقائق):',
      wait: '*هذا فيديو بدون نصوص. يُرجى الانتظار حتى يتم الانتهاء من عمليات إضافية.',
      live: 'مباشر',
      private: 'خاص',
      liveNotSupported: 'الفيديو المباشر غير مدعوم',
      privateNotSupported: 'الفيديو الخاص غير مدعوم',
      freeLimit: 'تقتصر الخطة المجانية على مقاطع الفيديو بدون نصوص لمدة أقل من 15 دقيقة',
      proLimit: 'تقتصر الخطة المحترفة على مقاطع الفيديو بدون نصوص لمدة أقل من ساعة واحدة',
      growthLimit: 'تقتصر خطة النمو على مقاطع الفيديو بدون نصوص لمدة أقل من 45 دقيقة',
      essentialLimit: 'تقتصر الخطة الأساسية على مقاطع الفيديو بدون نصوص لمدة أقل من 30 دقيقة',
      unavailable: 'غير متوفر',
      upgradeText: 'ترقية',
      retry: 'إعادة المحاولة',
      ready: 'هذا الفيديو جاهز للدردشة',
      gettingReady: 'نحن نستعد للدردشة...',
      currentStatus: 'الحالة الحالية:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'جارٍ التحميل',
      PREPARING: 'جارٍ التحضير',
      THINKING: 'جارٍ التفكير',
      GENERATING_QUESTIONS: 'جارٍ إنشاء الأسئلة',
      ANALYZING_DATA: 'جارٍ تحليل البيانات',
      FINALIZING_REPLY: 'جارٍ إنهاء الرد',
      EVALUATING_POSSIBLE_SOLUTION: 'جارٍ تقييم الحلول الممكنة',
    },
    briefSummary: 'ملخص موجز',
    retry: 'إعادة المحاولة',
    mightAsk: 'قد ترغب في طرح الأسئلة التالية...',
    transcripts: 'النصوص',
    subscribe: 'الاشتراك',
    copied: 'تم النسخ',
    copyConversation: 'نسخ المحادثة',
    loading: 'جارٍ التحميل...',
    copyShare: 'نسخ الرابط ومشاركته',
    messageLeft: 'الرسائل المتبقية:',
    writeQuestion: 'اكتب سؤالًا...',
    copy: 'نسخ',
  },

  modal: {
    history: {
      title: 'سجل الفيديو',
      loading: 'جارٍ التحميل...',
      reachEnd: 'لقد وصلت إلى النهاية',
      noHistory: 'ليس لديك سجل فيديو',
      searchPlaceholder: 'البحث في سجل الفيديو...',
      noMatch: 'يبدو أنه لا يوجد الكثير من المطابقات الرائعة للبحث الخاص بك',
    },

    premium: {
      cancelled: 'تم إلغاء الاشتراك',
      nextPayment: 'الدفعة التالية في',
      pendingOrder:
        'لقد لاحظنا أن طلبك لا يزال قيد المعالجة. يُرجى المتابعة لإنهاء الدفع الخاص بك.',
      paymentComplete: 'تم الانتهاء من الدفع؟',
      askComplete: 'هل تم توجيهك إلى الصفحة الجديدة واكتمال عملية الدفع؟',
      confirm: 'تأكيد',
      cancel: 'إلغاء',
      benefitTitle: 'مع هذه الخطة، تحصل على الفوائد التالية:',
      subscribe: 'الاشتراك',

      plans: {
        essential: {
          video: '30 مقطع فيديو في اليوم',
          message: '30 رسالة لكل فيديو',
          transcript_free: 'معالجة مقاطع الفيديو بدون نصوص (مدة كل فيديو تصل إلى 30 دقيقة)',
          desc: 'مثالية للاستخدام المعتدل، توفر وصولًا يوميًا كبيرًا إلى محادثات الفيديو.',
        },
        growth: {
          video: '100 مقطع فيديو في اليوم',
          message: '100 رسالة لكل فيديو',
          transcript_free: 'معالجة مقاطع الفيديو بدون نصوص (مدة كل فيديو تصل إلى 45 دقيقة)',
          desc: 'قم بفتح إمكانيات أكبر مع محادثات الفيديو الموسعة وإمكانيات الرسائل.',
        },
        pro: {
          video: 'مقاطع فيديو غير محدودة في اليوم',
          message: 'رسائل غير محدودة لكل فيديو',
          transcript_free: 'معالجة مقاطع الفيديو بدون نصوص (مدة كل فيديو تصل إلى ساعة واحدة)',
          desc: 'افتح الباب أمام التفاعلات غير المحدودة مع وصول دائم طوال اليوم إلى الدردشة في كل فيديو.',
        },
      },

      prioritySupport: 'الدعم الأولوي عبر البريد الإلكتروني',
      priorityAccess: 'الوصول الأولوي إلى الميزات الجديدة',
      monthly: 'شهريًا',
      yearly: 'سنوياً',
      manageSubscription: 'إدارة اشتراكك هنا',
      needSupport: 'هل تحتاج إلى الدعم؟ اتصل بـ:',
      mostPopular: 'الأكثر شيوعًا',
      notSatisfied: 'لست راضيًا؟ لا تقلق.',
      offerRefund: 'نحن نقدم استردادًا خلال 14 يومًا من الشراء.',
      yourPlan: 'خطتك',
      upgradeYourPlan: 'قم بترقية خطتك',
    },

    settings: {
      title: 'الإعدادات',
      signedInAs: 'تم تسجيل الدخول كـ',
      email: 'البريد الإلكتروني',
      language: 'اللغة',
      subscribe: 'الاشتراك',
      signOut: 'تسجيل الخروج',
    },
  },

  promptSuggestions: {
    turnOff: 'إيقاف اقتراحات الأوامر',
    title: 'اقتراحات الأوامر',
    description: 'تفعيل هذا الخيار سيوفر اقتراحات الأوامر بعد كل استجابة',
  },
}
