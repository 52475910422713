export const ru = {
  error: {
    unknown:
      'Произошла ошибка. Пожалуйста, попробуйте еще раз. Если проблема не устраняется, обратитесь в службу поддержки.',
    errMsg: {
      DONE: 'Произошла ошибка. Обновите страницу или обратитесь в службу поддержки, если ошибка повторяется.',
      RETRY: 'Произошла ошибка.',
      TOKEN_LIMIT: 'Достигнут лимит токенов.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Войдите, чтобы общаться с этим видео',
    },

    videoCountButton: {
      noVideosLeft: 'Видео закончились; вернитесь завтра в 12:00 по тихоокеанскому времени.',
      noFreeVideosLeft:
        'Бесплатные видео закончились; вернитесь завтра в 12:00 по тихоокеанскому времени.',
      videosRemainingToday: 'Осталось видео сегодня:',
      upgradePlan: '(Нажмите, чтобы улучшить свой план)',
      getMore: '(Нажмите, чтобы получить больше)',
    },

    historyButton: {
      title: 'История видео',
    },

    supportButton: {
      title: 'Поддержка',
    },

    settingsButton: {
      title: 'Настройки',
    },

    action: {
      slowNetwork:
        'Упс! Ваша сеть кажется медленной. Пожалуйста, убедитесь в стабильном и быстром подключении и обновите страницу, чтобы проверить, повторяется ли проблема.',
      noVideosLeft: 'Видео закончились...',
      noFreeVideosLeft: 'Бесплатные видео закончились...',
      btn: 'Краткое содержание и общение',
      comeback: 'Вернитесь завтра в 12:00 по тихоокеанскому времени.',
      upgrade:
        'Или прямо сейчас улучшите свой план, чтобы получить мгновенный доступ к неограниченным ежедневным видео 🚀',
      process: 'Обработать это видео',
      expectLengthy: 'Ожидайте длительного времени ожидания.',
      average: 'Среднее время ожидания (в минутах):',
      wait: '*Это видео без транскриптов. Пожалуйста, дождитесь завершения дополнительных процессов.',
      live: 'Прямая трансляция',
      private: 'Частное видео',
      liveNotSupported: 'Прямая трансляция не поддерживается',
      privateNotSupported: 'Частное видео не поддерживается',
      freeLimit:
        'Бесплатный план ограничен видео без транскриптов продолжительностью менее 15 минут',
      proLimit: 'План Pro ограничен видео без транскриптов продолжительностью менее 1 часа',
      growthLimit: 'План Growth ограничен видео без транскриптов продолжительностью менее 45 минут',
      essentialLimit:
        'План Essential ограничен видео без транскриптов продолжительностью менее 30 минут',
      unavailable: 'Недоступно',
      upgradeText: 'Улучшить',
      retry: 'Повторить попытку',
      ready: 'Это видео готово для общения',
      gettingReady: 'Мы готовимся к вашему общению...',
      currentStatus: 'Текущий статус:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Загрузка',
      PREPARING: 'Подготовка',
      THINKING: 'Мысли',
      GENERATING_QUESTIONS: 'Генерация вопросов',
      ANALYZING_DATA: 'Анализ данных',
      FINALIZING_REPLY: 'Формирование ответа',
      EVALUATING_POSSIBLE_SOLUTION: 'Оценка возможных решений',
    },
    briefSummary: 'Краткое содержание',
    retry: 'Повторить попытку',
    mightAsk: 'Возможно, вам захочется спросить...',
    transcripts: 'Транскрипты',
    subscribe: 'Подписаться',
    copied: 'Скопировано',
    copyConversation: 'Скопировать беседу',
    loading: 'Загрузка...',
    copyShare: 'Скопировать ссылку и поделиться',
    messageLeft: 'Осталось сообщений:',
    writeQuestion: 'Задайте вопрос...',
    copy: 'Копировать',
  },

  modal: {
    history: {
      title: 'История видео',
      loading: 'Загрузка...',
      reachEnd: 'Вы достигли конца',
      noHistory: 'У вас нет истории видео',
      searchPlaceholder: 'Поиск в истории видео...',
      noMatch: 'Похоже, что для вашего поиска нет подходящих результатов',
    },

    premium: {
      cancelled: 'Подписка отменена',
      nextPayment: 'Следующий платеж',
      pendingOrder:
        'Мы заметили, что ваш заказ все еще ожидает обработки. Пожалуйста, продолжите оформление платежа.',
      paymentComplete: 'Платеж завершен?',
      askComplete: 'Вы были перенаправлены на новую страницу и завершили платеж?',
      confirm: 'Подтвердить',
      cancel: 'Отменить',
      benefitTitle: 'С этим планом вы получаете следующие преимущества:',
      subscribe: 'Подписаться',

      plans: {
        essential: {
          video: '30 видео в день',
          message: '30 сообщений на видео',
          transcript_free: 'Обработка видео без транскриптов (до 30 минут каждое)',
          desc: 'Идеально подходит для умеренного использования, предлагая значительный ежедневный доступ к видео-чатам.',
        },
        growth: {
          video: '100 видео в день',
          message: '100 сообщений на видео',
          transcript_free: 'Обработка видео без транскриптов (до 45 минут каждое)',
          desc: 'Разблокируйте больше возможностей с расширенными видео-чатами и возможностями обмена сообщениями.',
        },
        pro: {
          video: 'Неограниченное количество видео в день',
          message: 'Неограниченное количество сообщений на видео',
          transcript_free: 'Обработка видео без транскриптов (до 1 часа каждое)',
          desc: 'Откройте дверь к бесконечным взаимодействиям с доступом к чату на всю день и на каждое видео.',
        },
      },

      prioritySupport: 'Приоритетная поддержка по электронной почте',
      priorityAccess: 'Приоритетный доступ к новым функциям',
      monthly: 'Ежемесячно',
      yearly: 'Ежегодно',
      manageSubscription: 'Управление вашей подпиской здесь',
      needSupport: 'Нужна поддержка? Свяжитесь с:',
      mostPopular: 'Самое популярное',
      notSatisfied: 'Не удовлетворены? Не беда.',
      offerRefund: 'Мы предоставляем возврат денег в течение 14 дней с момента покупки.',
      yourPlan: 'Ваш план',
      upgradeYourPlan: 'Улучшите свой план',
    },

    settings: {
      title: 'Настройки',
      signedInAs: 'Вы вошли как',
      email: 'Электронная почта',
      language: 'Язык',
      subscribe: 'Подписаться',
      signOut: 'Выйти',
    },
  },

  promptSuggestions: {
    turnOff: 'Отключить предложения',
    title: 'Предложения подсказок',
    description: 'Его активация обеспечит предложения после каждого ответа',
  },
}
