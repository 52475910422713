export const ja = {
  error: {
    unknown:
      'エラーが発生しました。再試行してください。問題が解決しない場合はサポートに連絡してください。',
    errMsg: {
      DONE: 'エラーが発生しました。ページを更新するか、問題が解決しない場合はサポートに連絡してください。',
      RETRY: 'エラーが発生しました。',
      TOKEN_LIMIT: 'トークンの制限に達しました。',
    },
  },

  header: {
    withoutAuth: {
      btn: 'このビデオでチャットするにはサインインしてください',
    },

    videoCountButton: {
      noVideosLeft: 'ビデオが残っていません。明日の太平洋時間午前12時に戻ってきてください。',
      noFreeVideosLeft:
        '無料のビデオが残っていません。明日の太平洋時間午前12時に戻ってきてください。',
      videosRemainingToday: '今日の残りのビデオ数:',
      upgradePlan: '(プランをアップグレードするにはクリックしてください)',
      getMore: '(さらに取得するにはクリックしてください)',
    },

    historyButton: {
      title: 'ビデオ履歴',
    },

    supportButton: {
      title: 'サポート',
    },

    settingsButton: {
      title: '設定',
    },

    action: {
      slowNetwork:
        'おっと！ネットワークが遅いようです。安定した高速接続を確保し、問題が解決するかどうかを確認するためにページを更新してください。',
      noVideosLeft: 'ビデオが残っていません...',
      noFreeVideosLeft: '無料のビデオが残っていません...',
      btn: '要約してチャットする',
      comeback: '明日の太平洋時間午前12時に戻ってきてください。',
      upgrade:
        'または今すぐアップグレードして、毎日無制限のビデオへの即時アクセスを取得してください 🚀',
      process: 'このビデオを処理する',
      expectLengthy: '長い待ち時間が予想されます。',
      average: '平均待ち時間（分）:',
      wait: '*このビデオはトランスクリプトのないビデオです。追加の処理が完了するまでお待ちください。',
      live: 'ライブ',
      private: 'プライベート',
      liveNotSupported: 'ライブビデオはサポートされていません',
      privateNotSupported: 'プライベートビデオはサポートされていません',
      freeLimit: '無料プランはトランスクリプトのないビデオに制限され、15分以下です',
      proLimit: 'プロプランはトランスクリプトのないビデオに制限され、1時間以下です',
      growthLimit: 'グロースプランはトランスクリプトのないビデオに制限され、45分以下です',
      essentialLimit: 'エッセンシャルプランはトランスクリプトのないビデオに制限され、30分以下です',
      unavailable: '利用できません',
      upgradeText: 'アップグレード',
      retry: '再試行',
      ready: 'このビデオはチャットの準備ができています',
      gettingReady: 'チャットの準備中です...',
      currentStatus: '現在のステータス:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: '読み込み中',
      PREPARING: '準備中',
      THINKING: '考え中',
      GENERATING_QUESTIONS: '質問を生成中',
      ANALYZING_DATA: 'データを分析中',
      FINALIZING_REPLY: '返信を最終化中',
      EVALUATING_POSSIBLE_SOLUTION: '可能な解決策を評価中',
    },
    briefSummary: '概要',
    retry: '再試行',
    mightAsk: '尋ねたいかもしれないこと...',
    transcripts: 'トランスクリプト',
    subscribe: '購読する',
    copied: 'コピー済み',
    copyConversation: '会話をコピー',
    loading: '読み込み中...',
    copyShare: 'リンクをコピーして共有',
    messageLeft: '残りのメッセージ数:',
    writeQuestion: '質問を書く...',
    copy: 'コピー',
  },

  modal: {
    history: {
      title: 'ビデオ履歴',
      loading: '読み込み中...',
      reachEnd: '最後まで到達しました',
      noHistory: 'ビデオ履歴がありません',
      searchPlaceholder: 'ビデオ履歴を検索...',
      noMatch: '検索に一致するものが見つかりませんでした',
    },

    premium: {
      cancelled: '購読がキャンセルされました',
      nextPayment: '次回の支払い日:',
      pendingOrder: '注文がまだ保留中です。お支払いを完了してください。',
      paymentComplete: '支払いが完了しましたか？',
      askComplete: '新しいページにリダイレクトされ、支払いが完了しましたか？',
      confirm: '確認',
      cancel: 'キャンセル',
      benefitTitle: 'このプランでは、以下の特典があります:',
      subscribe: '購読する',

      plans: {
        essential: {
          video: '1日30本のビデオ',
          message: 'ビデオごとに30件のメッセージ',
          transcript_free: 'トランスクリプトのないビデオを処理する（各30分まで）',
          desc: '中程度の使用に最適で、ビデオチャットへの毎日のアクセスが提供されます。',
        },
        growth: {
          video: '1日100本のビデオ',
          message: 'ビデオごとに100件のメッセージ',
          transcript_free: 'トランスクリプトのないビデオを処理する（各45分まで）',
          desc: '拡張されたビデオチャットとメッセージング機能でより多くの可能性を開放します。',
        },
        pro: {
          video: '1日無制限のビデオ',
          message: 'ビデオごとに無制限のメッセージ',
          transcript_free: 'トランスクリプトのないビデオを処理する（各1時間まで）',
          desc: '終日、すべてのビデオチャットへの無限のインタラクションを提供します。',
        },
      },

      prioritySupport: '優先メールサポート',
      priorityAccess: '新機能への優先アクセス',
      monthly: '月額',
      yearly: '年間',
      manageSubscription: '購読を管理する',
      needSupport: 'サポートが必要ですか？お問い合わせ先:',
      mostPopular: '最も人気',
      notSatisfied: '満足していませんか？心配ありません。',
      offerRefund: '購入後14日以内に返金を提供します。',
      yourPlan: 'あなたのプラン',
      upgradeYourPlan: 'プランをアップグレードする',
    },

    settings: {
      title: '設定',
      signedInAs: 'サインイン済み:',
      email: 'メール',
      language: '言語',
      subscribe: '購読',
      signOut: 'サインアウト',
    },
  },

  promptSuggestions: {
    turnOff: '提案をオフにする',
    title: 'プロンプト提案',
    description: 'これを有効にすると、各応答の後にプロンプトの提案が表示されます',
  },
}
