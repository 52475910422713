export const ko = {
  error: {
    unknown: '오류가 발생했습니다. 다시 시도해주세요. 문제가 지속되면 지원팀에 문의하세요.',
    errMsg: {
      DONE: '오류가 발생했습니다. 페이지를 새로고침하거나 지속되면 지원팀에 문의하세요.',
      RETRY: '오류가 발생했습니다.',
      TOKEN_LIMIT: '토큰 한도를 초과했습니다.',
    },
  },

  header: {
    withoutAuth: {
      btn: '이 비디오로 채팅하려면 로그인하세요',
    },

    videoCountButton: {
      noVideosLeft:
        '오늘은 더 이상 비디오가 없습니다. 내일 태평양 시간 오전 12시에 다시 방문하세요.',
      noFreeVideosLeft:
        '무료 비디오가 더 이상 남아있지 않습니다. 내일 태평양 시간 오전 12시에 다시 방문하세요.',
      videosRemainingToday: '오늘 남은 비디오:',
      upgradePlan: '(플랜 업그레이드를 클릭하세요)',
      getMore: '(더 많은 비디오를 받으려면 클릭하세요)',
    },

    historyButton: {
      title: '비디오 기록',
    },

    supportButton: {
      title: '지원',
    },

    settingsButton: {
      title: '설정',
    },

    action: {
      slowNetwork:
        '이런! 네트워크가 느린 것 같습니다. 안정적이고 빠른 연결을 확인하고 문제가 지속되는지 확인하기 위해 페이지를 새로고침하세요.',
      noVideosLeft: '더 이상 비디오가 없습니다...',
      noFreeVideosLeft: '무료 비디오가 더 이상 남아있지 않습니다...',
      btn: '요약 및 채팅',
      comeback: '내일 태평양 시간 오전 12시에 다시 방문하세요.',
      upgrade: '또는 지금 업그레이드하여 매일 무제한 비디오에 즉시 액세스하세요 🚀',
      process: '이 비디오 처리',
      expectLengthy: '긴 대기 시간이 예상됩니다.',
      average: '평균 대기 시간 (분):',
      wait: '*이 비디오에는 대본이 없습니다. 추가 처리가 완료될 때까지 기다려주세요.',
      live: '실시간',
      private: '비공개',
      liveNotSupported: '실시간 비디오는 지원되지 않습니다.',
      privateNotSupported: '비공개 비디오는 지원되지 않습니다.',
      freeLimit: '무료 플랜은 대본이 없는 비디오를 15분 이하로 제한합니다.',
      proLimit: '프로 플랜은 대본이 없는 비디오를 1시간 이하로 제한합니다.',
      growthLimit: '그로스 플랜은 대본이 없는 비디오를 45분 이하로 제한합니다.',
      essentialLimit: '에센셜 플랜은 대본이 없는 비디오를 30분 이하로 제한합니다.',
      unavailable: '사용할 수 없음',
      upgradeText: '업그레이드',
      retry: '재시도',
      ready: '이 비디오는 채팅할 준비가 되었습니다.',
      gettingReady: '채팅 준비 중입니다...',
      currentStatus: '현재 상태:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: '로딩 중',
      PREPARING: '준비 중',
      THINKING: '생각 중',
      GENERATING_QUESTIONS: '질문 생성 중',
      ANALYZING_DATA: '데이터 분석 중',
      FINALIZING_REPLY: '답변 완료 중',
      EVALUATING_POSSIBLE_SOLUTION: '가능한 해결책 평가 중',
    },
    briefSummary: '간단한 요약',
    retry: '재시도',
    mightAsk: '질문할 수 있습니다...',
    transcripts: '대본',
    subscribe: '구독',
    copied: '복사됨',
    copyConversation: '대화 복사',
    loading: '로딩 중...',
    copyShare: '링크 복사 및 공유',
    messageLeft: '남은 메시지:',
    writeQuestion: '질문 작성...',
    copy: '복사',
  },

  modal: {
    history: {
      title: '비디오 기록',
      loading: '로딩 중...',
      reachEnd: '마지막에 도달했습니다',
      noHistory: '비디오 기록이 없습니다',
      searchPlaceholder: '비디오 기록 검색...',
      noMatch: '검색에 일치하는 결과가 없습니다',
    },

    premium: {
      cancelled: '구독이 취소되었습니다',
      nextPayment: '다음 결제일',
      pendingOrder: '주문이 아직 처리 중인 것 같습니다. 결제를 완료해주세요.',
      paymentComplete: '결제 완료되었나요?',
      askComplete: '새 페이지로 리디렉션되어 결제를 완료했나요?',
      confirm: '확인',
      cancel: '취소',
      benefitTitle: '이 플랜으로 다음 혜택을 받으실 수 있습니다:',
      subscribe: '구독',

      plans: {
        essential: {
          video: '하루에 30개의 비디오',
          message: '비디오 당 30개의 메시지',
          transcript_free: '대본이 없는 비디오 처리 (각각 최대 30분)',
          desc: '중간 정도 사용에 적합하며, 매일 비디오 채팅에 대한 충분한 액세스를 제공합니다.',
        },
        growth: {
          video: '하루에 100개의 비디오',
          message: '비디오 당 100개의 메시지',
          transcript_free: '대본이 없는 비디오 처리 (각각 최대 45분)',
          desc: '확장된 비디오 채팅과 메시징 기능으로 더 큰 가능성을 개방하세요.',
        },
        pro: {
          video: '하루에 무제한 비디오',
          message: '비디오 당 무제한 메시지',
          transcript_free: '대본이 없는 비디오 처리 (각각 최대 1시간)',
          desc: '하루 종일, 모든 비디오 채팅에 대한 무한한 상호작용을 열어보세요.',
        },
      },

      prioritySupport: '우선 이메일 지원',
      priorityAccess: '새로운 기능에 대한 우선 액세스',
      monthly: '매월',
      yearly: '연간',
      manageSubscription: '구독 관리하기',
      needSupport: '지원이 필요하신가요? 문의하기:',
      mostPopular: '가장 인기 있는 플랜',
      notSatisfied: '만족스럽지 않으신가요? 걱정하지 마세요.',
      offerRefund: '구매 후 14일 이내에 환불해드립니다.',
      yourPlan: '당신의 플랜',
      upgradeYourPlan: '플랜 업그레이드하기',
    },

    settings: {
      title: '설정',
      signedInAs: '사용자로 로그인됨',
      email: '이메일',
      language: '언어',
      subscribe: '구독',
      signOut: '로그아웃',
    },
  },

  promptSuggestions: {
    turnOff: '제안 기능 끄기',
    title: '프롬프트 제안',
    description: '이 기능을 활성화하면 각 응답 후에 프롬프트 제안이 제공됩니다',
  },
}
