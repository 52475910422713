export const fr = {
  error: {
    unknown:
      "Une erreur s'est produite. Veuillez réessayer. Contactez le support si le problème persiste.",
    errMsg: {
      DONE: "Une erreur s'est produite. Actualisez la page ou contactez le support si le problème persiste.",
      RETRY: "Une erreur s'est produite.",
      TOKEN_LIMIT: 'Limite de jetons atteinte.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Connectez-vous pour discuter avec cette vidéo',
    },

    videoCountButton: {
      noVideosLeft: 'Aucune vidéo restante. Revenez demain à 12h00 heure du Pacifique.',
      noFreeVideosLeft:
        'Aucune vidéo gratuite restante. Revenez demain à 12h00 heure du Pacifique.',
      videosRemainingToday: 'Vidéos restantes aujourd’hui :',
      upgradePlan: '(Cliquez pour mettre à niveau votre abonnement)',
      getMore: '(Cliquez pour obtenir plus)',
    },

    historyButton: {
      title: 'Historique des vidéos',
    },

    supportButton: {
      title: 'Support',
    },

    settingsButton: {
      title: 'Paramètres',
    },

    action: {
      slowNetwork:
        'Oops ! Votre réseau semble lent. Veuillez vous assurer d’une connexion stable et rapide, puis actualisez la page pour vérifier si le problème persiste.',
      noVideosLeft: 'Aucune vidéo restante...',
      noFreeVideosLeft: 'Aucune vidéo gratuite restante...',
      btn: 'Résumer et discuter',
      comeback: 'Revenez demain à 12h00 heure du Pacifique.',
      upgrade:
        'Ou mettez à niveau dès maintenant pour un accès instantané à des vidéos quotidiennes illimitées 🚀',
      process: 'Traiter cette vidéo',
      expectLengthy: 'Prévoyez un temps d’attente prolongé.',
      average: 'Temps d’attente moyen (en minutes) :',
      wait: '*Il s’agit d’une vidéo sans transcription. Veuillez attendre que les processus supplémentaires soient terminés.',
      live: 'En direct',
      private: 'Privée',
      liveNotSupported: 'La vidéo en direct n’est pas prise en charge',
      privateNotSupported: 'La vidéo privée n’est pas prise en charge',
      freeLimit:
        'L’abonnement gratuit est limité aux vidéos sans transcription de moins de 15 minutes',
      proLimit: "L'abonnement Pro est limité aux vidéos sans transcription de moins d'une heure",
      growthLimit:
        "L'abonnement Growth est limité aux vidéos sans transcription de moins de 45 minutes",
      essentialLimit:
        "L'abonnement Essential est limité aux vidéos sans transcription de moins de 30 minutes",
      unavailable: 'Indisponible',
      upgradeText: 'Mettre à niveau',
      retry: 'Réessayer',
      ready: 'Cette vidéo est prête à être discutée',
      gettingReady: 'Nous nous préparons pour votre discussion...',
      currentStatus: 'Statut actuel :',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Chargement',
      PREPARING: 'Préparation',
      THINKING: 'Réflexion',
      GENERATING_QUESTIONS: 'Génération de questions',
      ANALYZING_DATA: 'Analyse des données',
      FINALIZING_REPLY: 'Finalisation de la réponse',
      EVALUATING_POSSIBLE_SOLUTION: 'Évaluation des solutions possibles',
    },
    briefSummary: 'Résumé succinct',
    retry: 'Réessayer',
    mightAsk: 'Vous voudrez peut-être demander...',
    transcripts: 'Transcriptions',
    subscribe: 'Souscrire',
    copied: 'Copié',
    copyConversation: 'Copier la conversation',
    loading: 'Chargement...',
    copyShare: 'Copier le lien et partager',
    messageLeft: 'Messages restants :',
    writeQuestion: 'Écrire une question...',
    copy: 'Copier',
  },

  modal: {
    history: {
      title: 'Historique des vidéos',
      loading: 'Chargement...',
      reachEnd: 'Vous avez atteint la fin',
      noHistory: "Vous n'avez aucun historique de vidéos",
      searchPlaceholder: 'Rechercher dans l’historique des vidéos...',
      noMatch: "Il semble qu'il n'y ait pas beaucoup de correspondances pour votre recherche",
    },

    premium: {
      cancelled: 'L’abonnement est annulé',
      nextPayment: 'Prochain paiement le',
      pendingOrder:
        'Nous avons remarqué que votre commande est en attente. Veuillez procéder à la finalisation de votre paiement.',
      paymentComplete: 'Paiement terminé ?',
      askComplete:
        'Avez-vous été redirigé vers la nouvelle page et avez-vous terminé votre paiement ?',
      confirm: 'Confirmer',
      cancel: 'Annuler',
      benefitTitle: 'Avec cet abonnement, vous bénéficiez des avantages suivants :',
      subscribe: 'Souscrire',

      plans: {
        essential: {
          video: '30 vidéos par jour',
          message: '30 messages par vidéo',
          transcript_free: 'Traiter des vidéos sans transcription (jusqu’à 30 minutes chacune)',
          desc: 'Parfait pour une utilisation modérée, offrant un accès quotidien substantiel aux discussions vidéo.',
        },
        growth: {
          video: '100 vidéos par jour',
          message: '100 messages par vidéo',
          transcript_free: 'Traiter des vidéos sans transcription (jusqu’à 45 minutes chacune)',
          desc: 'Débloquez de plus grandes possibilités avec des discussions vidéo étendues et des capacités de messagerie.',
        },
        pro: {
          video: 'Vidéos illimitées par jour',
          message: 'Messages illimités par vidéo',
          transcript_free: 'Traiter des vidéos sans transcription (jusqu’à 1 heure chacune)',
          desc: 'Ouvrez la porte à des interactions infinies avec un accès aux discussions vidéo toute la journée, tous les jours.',
        },
      },

      prioritySupport: 'Support par e-mail prioritaire',
      priorityAccess: 'Accès prioritaire aux nouvelles fonctionnalités',
      monthly: 'Mensuellement',
      yearly: 'Annuellement',
      manageSubscription: 'Gérez votre abonnement ici',
      needSupport: 'Besoin d’aide ? Contactez :',
      mostPopular: 'Le plus populaire',
      notSatisfied: 'Pas satisfait ? Pas de soucis.',
      offerRefund: 'Nous offrons un remboursement dans les 14 jours suivant l’achat.',
      yourPlan: 'Votre abonnement',
      upgradeYourPlan: 'Mettez à niveau votre abonnement',
    },

    settings: {
      title: 'Paramètres',
      signedInAs: 'Connecté en tant que',
      email: 'E-mail',
      language: 'Langue',
      subscribe: 'Souscrire',
      signOut: 'Déconnexion',
    },
  },

  promptSuggestions: {
    turnOff: 'Désactiver les suggestions',
    title: "Suggestions d'invitations",
    description: 'Son activation fournira des suggestions après chaque réponse',
  },
}
