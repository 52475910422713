export const it = {
  error: {
    unknown:
      'Si è verificato un errore. Si prega di riprovare. Contattare il supporto se il problema persiste.',
    errMsg: {
      DONE: 'Si è verificato un errore. Aggiorna la pagina o contatta il supporto se il problema persiste.',
      RETRY: 'Si è verificato un errore.',
      TOKEN_LIMIT: 'Limite di token raggiunto.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Accedi per chattare con questo video',
    },

    videoCountButton: {
      noVideosLeft: 'Nessun video disponibile; torna domani alle 12:00 ora del Pacifico.',
      noFreeVideosLeft:
        'Nessun video gratuito disponibile; torna domani alle 12:00 ora del Pacifico.',
      videosRemainingToday: 'Video rimanenti oggi:',
      upgradePlan: '(Clicca per passare a un piano superiore)',
      getMore: '(Clicca per ottenerne altri)',
    },

    historyButton: {
      title: 'Cronologia video',
    },

    supportButton: {
      title: 'Supporto',
    },

    settingsButton: {
      title: 'Impostazioni',
    },

    action: {
      slowNetwork:
        'Ops! La tua connessione sembra lenta. Assicurati di avere una connessione stabile e veloce e aggiorna la pagina per verificare se il problema persiste.',
      noVideosLeft: 'Nessun video disponibile...',
      noFreeVideosLeft: 'Nessun video gratuito disponibile...',
      btn: 'Riassumi e chatta',
      comeback: 'Torna domani alle 12:00 ora del Pacifico.',
      upgrade:
        "Oppure effettua l'upgrade ora per un accesso istantaneo a video giornalieri illimitati 🚀",
      process: 'Elabora questo video',
      expectLengthy: 'Tempo di attesa prolungato previsto.',
      average: 'Tempo medio di attesa (in minuti):',
      wait: '*Questo è un video senza trascrizioni. Attendi il completamento di ulteriori processi.',
      live: 'In diretta',
      private: 'Privato',
      liveNotSupported: 'Il video in diretta non è supportato',
      privateNotSupported: 'Il video privato non è supportato',
      freeLimit:
        'Il piano gratuito è limitato a video senza trascrizioni di durata inferiore a 15 minuti',
      proLimit: 'Il piano Pro è limitato a video senza trascrizioni di durata inferiore a 1 ora',
      growthLimit:
        'Il piano Growth è limitato a video senza trascrizioni di durata inferiore a 45 minuti',
      essentialLimit:
        'Il piano Essential è limitato a video senza trascrizioni di durata inferiore a 30 minuti',
      unavailable: 'Non disponibile',
      upgradeText: 'Passa a un piano superiore',
      retry: 'Riprova',
      ready: 'Questo video è pronto per la chat',
      gettingReady: 'Ci stiamo preparando per la tua chat...',
      currentStatus: 'Stato attuale:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Caricamento',
      PREPARING: 'Preparazione',
      THINKING: 'Pensando',
      GENERATING_QUESTIONS: 'Generazione di domande',
      ANALYZING_DATA: 'Analisi dei dati',
      FINALIZING_REPLY: 'Finalizzazione della risposta',
      EVALUATING_POSSIBLE_SOLUTION: 'Valutazione delle possibili soluzioni',
    },
    briefSummary: 'Sommario breve',
    retry: 'Riprova',
    mightAsk: 'Potresti voler chiedere...',
    transcripts: 'Trascrizioni',
    subscribe: 'Abbonati',
    copied: 'Copiato',
    copyConversation: 'Copia conversazione',
    loading: 'Caricamento...',
    copyShare: 'Copia link e condividi',
    messageLeft: 'Messaggi rimanenti:',
    writeQuestion: 'Scrivi una domanda...',
    copy: 'Copia',
  },

  modal: {
    history: {
      title: 'Cronologia video',
      loading: 'Caricamento...',
      reachEnd: 'Hai raggiunto la fine',
      noHistory: 'Non hai una cronologia video',
      searchPlaceholder: 'Cerca nella cronologia video...',
      noMatch: 'Sembra che non ci siano molte corrispondenze per la tua ricerca',
    },

    premium: {
      cancelled: "L'abbonamento è stato annullato",
      nextPayment: 'Prossimo pagamento il',
      pendingOrder:
        'Abbiamo notato che il tuo ordine è ancora in sospeso. Procedi con il completamento del pagamento.',
      paymentComplete: 'Pagamento completato?',
      askComplete: 'Sei stato reindirizzato alla nuova pagina e hai completato il pagamento?',
      confirm: 'Conferma',
      cancel: 'Annulla',
      benefitTitle: 'Con questo piano, ricevi i seguenti vantaggi:',
      subscribe: 'Abbonati',

      plans: {
        essential: {
          video: '30 video al giorno',
          message: '30 messaggi per video',
          transcript_free: 'Elaborazione di video senza trascrizioni (fino a 30 minuti ciascuno)',
          desc: 'Per un utilizzo moderato, offre un accesso giornaliero sostanziale alle chat video.',
        },
        growth: {
          video: '100 video al giorno',
          message: '100 messaggi per video',
          transcript_free: 'Elaborazione di video senza trascrizioni (fino a 45 minuti ciascuno)',
          desc: 'Sblocca maggiori possibilità con chat video estese e capacità di messaggistica.',
        },
        pro: {
          video: 'Video illimitati al giorno',
          message: 'Messaggi illimitati per video',
          transcript_free: 'Elaborazione di video senza trascrizioni (fino a 1 ora ciascuno)',
          desc: 'Apri le porte a infinite interazioni con accesso alle chat video per tutto il giorno.',
        },
      },

      prioritySupport: 'Supporto prioritario via email',
      priorityAccess: 'Accesso prioritario alle nuove funzionalità',
      monthly: 'Mensile',
      yearly: 'Annualmente',
      manageSubscription: 'Gestisci il tuo abbonamento qui',
      needSupport: 'Hai bisogno di supporto? Contatta:',
      mostPopular: 'Più popolare',
      notSatisfied: 'Non soddisfatto? Nessun problema.',
      offerRefund: "Offriamo un rimborso entro 14 giorni dall'acquisto.",
      yourPlan: 'Il tuo piano',
      upgradeYourPlan: 'Passa a un piano superiore',
    },

    settings: {
      title: 'Impostazioni',
      signedInAs: 'Accesso come',
      email: 'Email',
      language: 'Lingua',
      subscribe: 'Abbonati',
      signOut: 'Esci',
    },
  },

  promptSuggestions: {
    turnOff: 'Disattiva i suggerimenti',
    title: 'Suggerimenti di prompt',
    description: 'Attivandolo, verranno forniti suggerimenti dopo ogni risposta',
  },
}
