// 记得在 plausible 界面也要新增，他才会显示
// Plausible - My Sites，hover 卡片有个设置按钮
// 点击后，点击 goals 就看到了
export const TRACK_EVENT = {
  FEEDBACK_MODAL: 'FEEDBACK_MODAL',

  HEADER_VIDEO_COUNT: 'HEADER_VIDEO_COUNT',
  HEADER_SUPPORT: 'HEADER_SUPPORT',
  HEADER_PROGRESS: 'HEADER_PROGRESS',
  HEADER_LOGOUT: 'HEADER_LOGOUT',
  HEADER_UPGRADE: 'HEADER_UPGRADE',
  HEADER_FEEDBACK_GIFT: 'HEADER_FEEDBACK_GIFT',
  HEADER_START_PROCESSING: 'HEADER_START_PROCESSING',
  HEADER_RETRY: 'HEADER_RETRY',
  HEADER_CHAT_LANGUAGE: 'HEADER_CHAT_LANGUAGE',
  HEADER_PROCESS_START_CHAT: 'HEADER_PROCESS_START_CHAT',
  NO_TRANSCRIPT_LEARN_MORE: 'NO_TRANSCRIPT_LEARN_MORE',

  PREMIUM_MODAL_ACCESS_BUTTON: 'PREMIUM_MODAL_ACCESS_BUTTON',
  PREMIUM_MODAL_MANAGE_SUBSCRIPTION: 'PREMIUM_MODAL_MANAGE_SUBSCRIPTION',

  CHAT_AREA_RATE: 'CHAT_AREA_RATE',
  CHAT_AREA_SUPPORT: 'CHAT_AREA_SUPPORT',
  CHAT_AREA_COPY: 'CHAT_AREA_COPY',
  CHAT_AREA_SUBTITLES: 'CHAT_AREA_SUBTITLES',
  CHAT_AREA_COPY_ALL: 'CHAT_AREA_COPY_ALL',
  CHAT_AREA_SUBSCRIBE: 'CHAT_AREA_SUBSCRIBE',
  CHAT_AREA_PREPARED: 'CHAT_AREA_PREPARED',
  CHAT_AREA_SHARE: 'CHAT_AREA_SHARE',

  CHAT_AREA_TURN_OFF_SUGGESTIONS: 'CHAT_AREA_TURN_OFF_SUGGESTIONS',

  SETTING_ENABLE_SUGGESTIONS: 'SETTING_ENABLE_SUGGESTIONS',
  SETTING_DISABLE_SUGGESTIONS: 'SETTING_DISABLE_SUGGESTIONS',
}
