import * as Sentry from '@sentry/react'

import {DEV} from '@/config'
import '../public-path'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: DEV ? 'develop_web' : 'production_web',
    dsn: 'https://eb090b8f5d06d214d5c02cc9c12e9117@o386156.ingest.sentry.io/4505627134328832',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/chattube\.io\/api/,
          /^https:\/\/develop\.chattube\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

import('./bootstrap')
