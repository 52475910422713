import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getFunctions} from 'firebase/functions'
import {initializeFirestore} from 'firebase/firestore'

import {DEV, FIREBASE_CONFIG} from './config'

const firebaseConfig = DEV ? FIREBASE_CONFIG.DEV : FIREBASE_CONFIG.PROD

export const app = initializeApp(firebaseConfig)
export const db = initializeFirestore(app, {experimentalForceLongPolling: true})
export const functions = getFunctions(app)
export const auth = getAuth(app)
