export const pl = {
  error: {
    unknown:
      'Wystąpił błąd. Spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.',
    errMsg: {
      DONE: 'Wystąpił błąd. Odśwież stronę lub skontaktuj się z pomocą techniczną, jeśli problem będzie się powtarzał.',
      RETRY: 'Wystąpił błąd.',
      TOKEN_LIMIT: 'Osiągnięto limit tokenów.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Zaloguj się, aby porozmawiać z tym wideo',
    },

    videoCountButton: {
      noVideosLeft: 'Brak dostępnych filmów; wróć o 12:00 czasu pacyficznego jutro.',
      noFreeVideosLeft:
        'Brak dostępnych bezpłatnych filmów; wróć o 12:00 czasu pacyficznego jutro.',
      videosRemainingToday: 'Pozostałe filmy dzisiaj:',
      upgradePlan: '(Kliknij, aby ulepszyć swój plan)',
      getMore: '(Kliknij, aby uzyskać więcej)',
    },

    historyButton: {
      title: 'Historia filmów',
    },

    supportButton: {
      title: 'Pomoc techniczna',
    },

    settingsButton: {
      title: 'Ustawienia',
    },

    action: {
      slowNetwork:
        'Ups! Twoje połączenie sieciowe wydaje się być wolne. Upewnij się, że masz stabilne i szybkie połączenie, a następnie odśwież stronę, aby sprawdzić, czy problem nadal występuje.',
      noVideosLeft: 'Brak dostępnych filmów...',
      noFreeVideosLeft: 'Brak dostępnych bezpłatnych filmów...',
      btn: 'Podsumuj i porozmawiaj',
      comeback: 'Wróć o 12:00 czasu pacyficznego jutro.',
      upgrade:
        'Lub ulepsz teraz, aby mieć natychmiastowy dostęp do nieograniczonej liczby filmów dziennie 🚀',
      process: 'Przetwórz to wideo',
      expectLengthy: 'Oczekuj długiego czasu oczekiwania.',
      average: 'Średni czas oczekiwania (w minutach):',
      wait: '*To jest wideo bez transkryptu. Poczekaj na zakończenie dodatkowych procesów.',
      live: 'Na żywo',
      private: 'Prywatne',
      liveNotSupported: 'Wideo na żywo nie jest obsługiwane',
      privateNotSupported: 'Wideo prywatne nie jest obsługiwane',
      freeLimit: 'Darmowy plan ograniczony jest do filmów bez transkryptów o długości do 15 minut',
      proLimit: 'Plan Pro ogranicza filmy bez transkryptów do 1 godziny',
      growthLimit: 'Plan Growth ogranicza filmy bez transkryptów do 45 minut',
      essentialLimit: 'Plan Essential ogranicza filmy bez transkryptów do 30 minut',
      unavailable: 'Niedostępne',
      upgradeText: 'Ulepsz',
      retry: 'Ponów',
      ready: 'To wideo jest gotowe do rozmowy',
      gettingReady: 'Przygotowujemy się do Twojej rozmowy...',
      currentStatus: 'Aktualny status:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Ładowanie',
      PREPARING: 'Przygotowywanie',
      THINKING: 'Myślenie',
      GENERATING_QUESTIONS: 'Generowanie pytań',
      ANALYZING_DATA: 'Analizowanie danych',
      FINALIZING_REPLY: 'Finalizowanie odpowiedzi',
      EVALUATING_POSSIBLE_SOLUTION: 'Ocenianie możliwych rozwiązań',
    },
    briefSummary: 'Krótkie podsumowanie',
    retry: 'Ponów',
    mightAsk: 'Możesz zapytać...',
    transcripts: 'Transkrypty',
    subscribe: 'Subskrybuj',
    copied: 'Skopiowane',
    copyConversation: 'Skopiuj rozmowę',
    loading: 'Ładowanie...',
    copyShare: 'Skopiuj link i udostępnij',
    messageLeft: 'Pozostałe wiadomości:',
    writeQuestion: 'Napisz pytanie...',
    copy: 'Kopiuj',
  },

  modal: {
    history: {
      title: 'Historia filmów',
      loading: 'Ładowanie...',
      reachEnd: 'Osiągnąłeś koniec',
      noHistory: 'Nie masz historii filmów',
      searchPlaceholder: 'Wyszukaj historię filmów...',
      noMatch: 'Wygląda na to, że nie ma wielu dobrych dopasowań dla Twojego wyszukiwania',
    },

    premium: {
      cancelled: 'Subskrypcja została anulowana',
      nextPayment: 'Następna płatność:',
      pendingOrder:
        'Zauważyliśmy, że Twoje zamówienie jest w trakcie przetwarzania. Prosimy przejdź do finalizacji płatności.',
      paymentComplete: 'Płatność zakończona?',
      askComplete: 'Czy zostałeś przekierowany na nową stronę i zakończyłeś płatność?',
      confirm: 'Potwierdź',
      cancel: 'Anuluj',
      benefitTitle: 'Z tym planem otrzymujesz następujące korzyści:',
      subscribe: 'Subskrybuj',

      plans: {
        essential: {
          video: '30 filmów dziennie',
          message: '30 wiadomości na film',
          transcript_free: 'Przetwarzanie filmów bez transkryptów (do 30 minut każdy)',
          desc: 'Idealny dla umiarkowanego użytkowania, oferuje znaczący dzienny dostęp do rozmów wideo.',
        },
        growth: {
          video: '100 filmów dziennie',
          message: '100 wiadomości na film',
          transcript_free: 'Przetwarzanie filmów bez transkryptów (do 45 minut każdy)',
          desc: 'Odblokuj większe możliwości dzięki rozszerzonym rozmowom wideo i możliwościom wysyłania wiadomości.',
        },
        pro: {
          video: 'Nieograniczone filmy dziennie',
          message: 'Nieograniczone wiadomości na film',
          transcript_free: 'Przetwarzanie filmów bez transkryptów (do 1 godziny każdy)',
          desc: 'Otwórz drzwi do niekończących się interakcji dzięki całodobowemu dostępowi do czatów wideo.',
        },
      },

      prioritySupport: 'Priorytetowe wsparcie e-mailowe',
      priorityAccess: 'Priorytetowy dostęp do nowych funkcji',
      monthly: 'Miesięcznie',
      yearly: 'Rocznie',
      manageSubscription: 'Zarządzaj swoją subskrypcją tutaj',
      needSupport: 'Potrzebujesz pomocy? Skontaktuj się z:',
      mostPopular: 'Najpopularniejszy',
      notSatisfied: 'Nie jesteś zadowolony? Nie ma problemu.',
      offerRefund: 'Oferujemy zwrot pieniędzy w ciągu 14 dni od zakupu.',
      yourPlan: 'Twój plan',
      upgradeYourPlan: 'Ulepsz swój plan',
    },

    settings: {
      title: 'Ustawienia',
      signedInAs: 'Zalogowany jako',
      email: 'E-mail',
      language: 'Język',
      subscribe: 'Subskrybuj',
      signOut: 'Wyloguj się',
    },
  },

  promptSuggestions: {
    turnOff: 'Wyłącz sugestie',
    title: 'Sugestie monitów',
    description: 'Włączenie tej opcji spowoduje wyświetlanie sugestii po każdej odpowiedzi',
  },
}
