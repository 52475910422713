export const de = {
  error: {
    unknown:
      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Kontaktieren Sie den Support, wenn das Problem weiterhin besteht.',
    errMsg: {
      DONE: 'Ein Fehler ist aufgetreten. Aktualisieren Sie die Seite oder kontaktieren Sie den Support, wenn das Problem weiterhin besteht.',
      RETRY: 'Ein Fehler ist aufgetreten.',
      TOKEN_LIMIT: 'Das Token-Limit wurde erreicht.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Melden Sie sich an, um mit diesem Video zu chatten',
    },

    videoCountButton: {
      noVideosLeft: 'Keine Videos mehr übrig; kommen Sie morgen um 12 Uhr Pazifischer Zeit zurück.',
      noFreeVideosLeft:
        'Keine kostenlosen Videos mehr übrig; kommen Sie morgen um 12 Uhr Pazifischer Zeit zurück.',
      videosRemainingToday: 'Videos verbleibend heute:',
      upgradePlan: '(Klicken Sie hier, um Ihren Plan zu aktualisieren)',
      getMore: '(Klicken Sie hier, um mehr zu erhalten)',
    },

    historyButton: {
      title: 'Videoverlauf',
    },

    supportButton: {
      title: 'Support',
    },

    settingsButton: {
      title: 'Einstellungen',
    },

    action: {
      slowNetwork:
        'Hoppla! Ihr Netzwerk scheint langsam zu sein. Bitte stellen Sie eine stabile, schnelle Verbindung her und aktualisieren Sie die Seite, um zu überprüfen, ob das Problem weiterhin besteht.',
      noVideosLeft: 'Keine Videos mehr übrig...',
      noFreeVideosLeft: 'Keine kostenlosen Videos mehr übrig...',
      btn: 'Zusammenfassen und chatten',
      comeback: 'Kommen Sie morgen um 12 Uhr Pazifischer Zeit zurück.',
      upgrade:
        'Oder aktualisieren Sie jetzt, um sofortigen Zugriff auf unbegrenzte tägliche Videos zu erhalten 🚀',
      process: 'Dieses Video verarbeiten',
      expectLengthy: 'Warten Sie auf eine längere Wartezeit.',
      average: 'Durchschnittliche Wartezeit (in Minuten):',
      wait: '*Dies ist ein Video ohne Transkripte. Bitte warten Sie, bis zusätzliche Prozesse abgeschlossen sind.',
      live: 'Live',
      private: 'Privat',
      liveNotSupported: 'Live-Video wird nicht unterstützt',
      privateNotSupported: 'Private Videos werden nicht unterstützt',
      freeLimit:
        'Der kostenlose Plan ist auf Videos ohne Transkripte von weniger als 15 Minuten begrenzt',
      proLimit: 'Der Pro-Plan ist auf Videos ohne Transkripte von weniger als 1 Stunde begrenzt',
      growthLimit:
        'Der Growth-Plan ist auf Videos ohne Transkripte von weniger als 45 Minuten begrenzt',
      essentialLimit:
        'Der Essential-Plan ist auf Videos ohne Transkripte von weniger als 30 Minuten begrenzt',
      unavailable: 'Nicht verfügbar',
      upgradeText: 'Upgrade',
      retry: 'Erneut versuchen',
      ready: 'Dieses Video ist bereit zum Chatten',
      gettingReady: 'Wir bereiten uns auf Ihren Chat vor...',
      currentStatus: 'Aktueller Status:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Laden',
      PREPARING: 'Vorbereiten',
      THINKING: 'Denken',
      GENERATING_QUESTIONS: 'Fragen generieren',
      ANALYZING_DATA: 'Daten analysieren',
      FINALIZING_REPLY: 'Antwort abschließen',
      EVALUATING_POSSIBLE_SOLUTION: 'Mögliche Lösungen evaluieren',
    },
    briefSummary: 'Kurze Zusammenfassung',
    retry: 'Erneut versuchen',
    mightAsk: 'Sie könnten fragen...',
    transcripts: 'Transkripte',
    subscribe: 'Abonnieren',
    copied: 'Kopiert',
    copyConversation: 'Konversation kopieren',
    loading: 'Laden...',
    copyShare: 'Link kopieren und teilen',
    messageLeft: 'Nachrichten übrig:',
    writeQuestion: 'Schreiben Sie eine Frage...',
    copy: 'Kopieren',
  },

  modal: {
    history: {
      title: 'Videoverlauf',
      loading: 'Laden...',
      reachEnd: 'Sie haben das Ende erreicht',
      noHistory: 'Sie haben keinen Videoverlauf',
      searchPlaceholder: 'Videoverlauf durchsuchen...',
      noMatch: 'Es scheint, dass es nicht viele gute Übereinstimmungen für Ihre Suche gibt',
    },

    premium: {
      cancelled: 'Das Abonnement wurde storniert',
      nextPayment: 'Nächste Zahlung am',
      pendingOrder:
        'Wir haben festgestellt, dass Ihre Bestellung noch aussteht. Bitte schließen Sie Ihre Zahlung ab.',
      paymentComplete: 'Zahlung abgeschlossen?',
      askComplete: 'Wurden Sie auf die neue Seite umgeleitet und haben Ihre Zahlung abgeschlossen?',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
      benefitTitle: 'Mit diesem Plan erhalten Sie folgende Vorteile:',
      subscribe: 'Abonnieren',

      plans: {
        essential: {
          video: '30 Videos pro Tag',
          message: '30 Nachrichten pro Video',
          transcript_free: 'Verarbeiten Sie Videos ohne Transkripte (bis zu 30 Minuten pro Video)',
          desc: 'Perfekt für den moderaten Gebrauch, bietet umfangreichen täglichen Zugriff auf Videochats.',
        },
        growth: {
          video: '100 Videos pro Tag',
          message: '100 Nachrichten pro Video',
          transcript_free: 'Verarbeiten Sie Videos ohne Transkripte (bis zu 45 Minuten pro Video)',
          desc: 'Entdecken Sie größere Möglichkeiten mit erweiterten Videochats und Messaging-Funktionen.',
        },
        pro: {
          video: 'Unbegrenzte Videos pro Tag',
          message: 'Unbegrenzte Nachrichten pro Video',
          transcript_free: 'Verarbeiten Sie Videos ohne Transkripte (bis zu 1 Stunde pro Video)',
          desc: 'Öffnen Sie die Tür zu unendlichen Interaktionen mit ganztägigem Zugriff auf Videochats.',
        },
      },

      prioritySupport: 'Priorisierter E-Mail-Support',
      priorityAccess: 'Priorisierter Zugriff auf neue Funktionen',
      monthly: 'Monatlich',
      yearly: 'Jährlich',
      manageSubscription: 'Verwalten Sie Ihr Abonnement hier',
      needSupport: 'Benötigen Sie Unterstützung? Kontakt:',
      mostPopular: 'Am beliebtesten',
      notSatisfied: 'Nicht zufrieden? Kein Problem.',
      offerRefund: 'Wir bieten eine Rückerstattung innerhalb von 14 Tagen nach dem Kauf an.',
      yourPlan: 'Ihr Plan',
      upgradeYourPlan: 'Ihren Plan aktualisieren',
    },

    settings: {
      title: 'Einstellungen',
      signedInAs: 'Angemeldet als',
      email: 'E-Mail',
      language: 'Sprache',
      subscribe: 'Abonnieren',
      signOut: 'Abmelden',
    },
  },

  promptSuggestions: {
    turnOff: 'Vorschläge ausschalten',
    title: 'Vorschlagsaufforderungen',
    description: 'Bei Aktivierung werden nach jeder Antwort Vorschläge angezeigt',
  },
}
