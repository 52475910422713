export const zh = {
  error: {
    unknown: '发生了错误，请重试。如果问题仍然存在，请联系支持部门。',
    errMsg: {
      DONE: '发生了错误。刷新页面或者联系支持部门。',
      RETRY: '发生了错误。',
      TOKEN_LIMIT: '已达到令牌限制。',
    },
  },

  header: {
    withoutAuth: {
      btn: '登录以与此视频聊天',
    },

    videoCountButton: {
      noVideosLeft: '没有剩余视频；明天太平洋时间凌晨12点再回来。',
      noFreeVideosLeft: '没有剩余免费视频；明天太平洋时间凌晨12点再回来。',
      videosRemainingToday: '今天剩余视频数量：',
      upgradePlan: '（点击升级您的计划）',
      getMore: '（点击获取更多）',
    },

    historyButton: {
      title: '视频历史',
    },

    supportButton: {
      title: '支持',
    },

    settingsButton: {
      title: '设置',
    },

    action: {
      slowNetwork:
        '哎呀！您的网络似乎很慢。请确保稳定、快速的连接，并刷新页面以检查问题是否仍然存在。',
      noVideosLeft: '没有剩余视频...',
      noFreeVideosLeft: '没有剩余免费视频...',
      btn: '总结并聊天',
      comeback: '明天太平洋时间凌晨12点再回来。',
      upgrade: '或立即升级，即可立即访问每日无限视频 🚀',
      process: '处理此视频',
      expectLengthy: '预计等待时间较长。',
      average: '平均等待时间（分钟）：',
      wait: '*这是一段没有转录的视频。请等待其他处理完成。',
      live: '直播',
      private: '私密',
      liveNotSupported: '不支持直播视频',
      privateNotSupported: '不支持私密视频',
      freeLimit: '免费计划仅限于不超过15分钟的没有转录的视频',
      proLimit: '专业计划仅限于不超过1小时的没有转录的视频',
      growthLimit: '增长计划仅限于不超过45分钟的没有转录的视频',
      essentialLimit: '基础计划仅限于不超过30分钟的没有转录的视频',
      unavailable: '不可用',
      upgradeText: '升级',
      retry: '重试',
      ready: '此视频已准备好聊天',
      gettingReady: '我们正在准备您的聊天...',
      currentStatus: '当前状态：',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: '加载中',
      PREPARING: '准备中',
      THINKING: '思考中',
      GENERATING_QUESTIONS: '生成问题',
      ANALYZING_DATA: '分析数据',
      FINALIZING_REPLY: '完成回复',
      EVALUATING_POSSIBLE_SOLUTION: '评估可能的解决方案',
    },
    briefSummary: '简要概述',
    retry: '重试',
    mightAsk: '您可能想问...',
    transcripts: '转录',
    subscribe: '订阅',
    copied: '已复制',
    copyConversation: '复制对话',
    loading: '加载中...',
    copyShare: '复制链接并分享',
    messageLeft: '剩余消息数：',
    writeQuestion: '编写问题...',
    copy: '复制',
  },

  modal: {
    history: {
      title: '视频历史',
      loading: '加载中...',
      reachEnd: '已到达末尾',
      noHistory: '您没有视频历史',
      searchPlaceholder: '搜索视频历史...',
      noMatch: '看起来您的搜索结果不多',
    },

    premium: {
      cancelled: '订阅已取消',
      nextPayment: '下次付款日期',
      pendingOrder: '我们注意到您的订单仍在等待处理。请继续完成付款。',
      paymentComplete: '付款完成？',
      askComplete: '您是否已被重定向到新页面并完成了付款？',
      confirm: '确认',
      cancel: '取消',
      benefitTitle: '通过此计划，您将获得以下优势：',
      subscribe: '订阅',

      plans: {
        essential: {
          video: '每天30个视频',
          message: '每个视频30条消息',
          transcript_free: '处理没有转录的视频（每个视频最长30分钟）',
          desc: '适合中等使用量，提供每日大量视频聊天的机会。',
        },
        growth: {
          video: '每天100个视频',
          message: '每个视频100条消息',
          transcript_free: '处理没有转录的视频（每个视频最长45分钟）',
          desc: '通过扩展的视频聊天和消息功能，开启更多可能性。',
        },
        pro: {
          video: '每天无限制视频',
          message: '每个视频无限制消息',
          transcript_free: '处理没有转录的视频（每个视频最长1小时）',
          desc: '打开无限互动之门，全天候、每个视频都可以聊天。',
        },
      },

      prioritySupport: '优先电子邮件支持',
      priorityAccess: '优先访问新功能',
      monthly: '每月',
      yearly: '每年',
      manageSubscription: '在此处管理您的订阅',
      needSupport: '需要支持？联系：',
      mostPopular: '最受欢迎',
      notSatisfied: '不满意？别担心。',
      offerRefund: '我们在购买后的14天内提供退款。',
      yourPlan: '您的计划',
      upgradeYourPlan: '升级您的计划',
    },

    settings: {
      title: '设置',
      signedInAs: '已登录为',
      email: '电子邮件',
      language: '语言',
      subscribe: '订阅',
      signOut: '退出登录',
    },
  },

  promptSuggestions: {
    turnOff: '关闭提示建议',
    title: '提示建议',
    description: '启用后，将在每次响应后提供提示建议',
  },
}
