export const es = {
  error: {
    unknown:
      'Se produjo un error. Por favor, inténtalo de nuevo. Ponte en contacto con el soporte si el problema persiste.',
    errMsg: {
      DONE: 'Se produjo un error. Actualiza la página o ponte en contacto con el soporte si persiste.',
      RETRY: 'Se produjo un error.',
      TOKEN_LIMIT: 'Se alcanzó el límite de tokens.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Inicia sesión para chatear con este video',
    },

    videoCountButton: {
      noVideosLeft: 'No quedan videos; vuelve mañana a las 12 a.m. hora del Pacífico.',
      noFreeVideosLeft:
        'No quedan videos gratuitos; vuelve mañana a las 12 a.m. hora del Pacífico.',
      videosRemainingToday: 'Videos restantes hoy:',
      upgradePlan: '(Haz clic para mejorar tu plan)',
      getMore: '(Haz clic para obtener más)',
    },

    historyButton: {
      title: 'Historial de videos',
    },

    supportButton: {
      title: 'Soporte',
    },

    settingsButton: {
      title: 'Configuración',
    },

    action: {
      slowNetwork:
        '¡Ups! Tu conexión de red parece lenta. Por favor, asegúrate de tener una conexión estable y rápida y actualiza la página para verificar si el problema persiste.',
      noVideosLeft: 'No quedan videos...',
      noFreeVideosLeft: 'No quedan videos gratuitos...',
      btn: 'Resumir y chatear',
      comeback: 'Vuelve mañana a las 12 a.m. hora del Pacífico.',
      upgrade: 'O actualiza ahora para acceder instantáneamente a videos diarios ilimitados 🚀',
      process: 'Procesar este video',
      expectLengthy: 'Espera un tiempo de espera prolongado.',
      average: 'Tiempo de espera promedio (en minutos):',
      wait: '*Este es un video sin transcripciones. Por favor, espera a que se completen los procesos adicionales.',
      live: 'En vivo',
      private: 'Privado',
      liveNotSupported: 'No se admite video en vivo',
      privateNotSupported: 'No se admite video privado',
      freeLimit:
        'El plan gratuito está limitado a videos sin transcripciones de menos de 15 minutos',
      proLimit: 'El plan Pro está limitado a videos sin transcripciones de menos de 1 hora',
      growthLimit:
        'El plan Growth está limitado a videos sin transcripciones de menos de 45 minutos',
      essentialLimit:
        'El plan Essential está limitado a videos sin transcripciones de menos de 30 minutos',
      unavailable: 'No disponible',
      upgradeText: 'Actualizar',
      retry: 'Reintentar',
      ready: 'Este video está listo para chatear',
      gettingReady: 'Nos estamos preparando para tu chat...',
      currentStatus: 'Estado actual:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Cargando',
      PREPARING: 'Preparando',
      THINKING: 'Pensando',
      GENERATING_QUESTIONS: 'Generando preguntas',
      ANALYZING_DATA: 'Analizando datos',
      FINALIZING_REPLY: 'Finalizando respuesta',
      EVALUATING_POSSIBLE_SOLUTION: 'Evaluando posibles soluciones',
    },
    briefSummary: 'Resumen breve',
    retry: 'Reintentar',
    mightAsk: 'Tal vez quieras preguntar...',
    transcripts: 'Transcripciones',
    subscribe: 'Suscribirse',
    copied: 'Copiado',
    copyConversation: 'Copiar conversación',
    loading: 'Cargando...',
    copyShare: 'Copiar enlace y compartir',
    messageLeft: 'Mensajes restantes:',
    writeQuestion: 'Escribe una pregunta...',
    copy: 'Copiar',
  },

  modal: {
    history: {
      title: 'Historial de videos',
      loading: 'Cargando...',
      reachEnd: 'Has llegado al final',
      noHistory: 'No tienes historial de videos',
      searchPlaceholder: 'Buscar en el historial de videos...',
      noMatch: 'Parece que no hay muchas coincidencias para tu búsqueda',
    },

    premium: {
      cancelled: 'La suscripción ha sido cancelada',
      nextPayment: 'Próximo pago el',
      pendingOrder:
        'Hemos notado que tu pedido aún está pendiente. Por favor, procede a finalizar tu pago.',
      paymentComplete: '¿Pago completado?',
      askComplete: '¿Has sido redirigido a la nueva página y has completado tu pago?',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      benefitTitle: 'Con este plan, recibes los siguientes beneficios:',
      subscribe: 'Suscribirse',

      plans: {
        essential: {
          video: '30 videos al día',
          message: '30 mensajes por video',
          transcript_free: 'Procesar videos sin transcripciones (hasta 30 minutos cada uno)',
          desc: 'Perfecto para uso moderado, ofrece un acceso diario sustancial a chats de video.',
        },
        growth: {
          video: '100 videos al día',
          message: '100 mensajes por video',
          transcript_free: 'Procesar videos sin transcripciones (hasta 45 minutos cada uno)',
          desc: 'Desbloquea mayores posibilidades con chats de video ampliados y capacidades de mensajería.',
        },
        pro: {
          video: 'Videos ilimitados al día',
          message: 'Mensajes ilimitados por video',
          transcript_free: 'Procesar videos sin transcripciones (hasta 1 hora cada uno)',
          desc: 'Abre la puerta a interacciones infinitas con acceso a chats de video durante todo el día.',
        },
      },

      prioritySupport: 'Soporte prioritario por correo electrónico',
      priorityAccess: 'Acceso prioritario a nuevas funciones',
      monthly: 'Mensual',
      yearly: 'Anualmente',
      manageSubscription: 'Administra tu suscripción aquí',
      needSupport: '¿Necesitas ayuda? Contacta con:',
      mostPopular: 'Más popular',
      notSatisfied: '¿No estás satisfecho? No te preocupes.',
      offerRefund: 'Ofrecemos un reembolso dentro de los 14 días de la compra.',
      yourPlan: 'Tu plan',
      upgradeYourPlan: 'Mejora tu plan',
    },

    settings: {
      title: 'Configuración',
      signedInAs: 'Iniciaste sesión como',
      email: 'Correo electrónico',
      language: 'Idioma',
      subscribe: 'Suscribirse',
      signOut: 'Cerrar sesión',
    },
  },

  promptSuggestions: {
    turnOff: 'Desactivar las sugerencias',
    title: 'Sugerencias de indicaciones',
    description: 'Al activarlo, se ofrecerán sugerencias después de cada respuesta',
  },
}
