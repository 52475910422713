export const en = {
  error: {
    unknown: "There's an error occurred. Please try again. Contact support if the issue persists.",
    errMsg: {
      DONE: 'An error occurred. Refresh the page or contact support if it persists.',
      RETRY: 'An error occurred.',
      TOKEN_LIMIT: 'Token limit is reached.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Sign in to chat with this video',
    },

    videoCountButton: {
      noVideosLeft: 'No videos left; come back at 12 a.m. Pacific Time tomorrow.',
      noFreeVideosLeft: 'No free videos left; come back at 12 a.m. Pacific Time tomorrow.',
      videosRemainingToday: 'Videos remaining today:',
      upgradePlan: '(Click to upgrade your plan)',
      getMore: '(Click to get more)',
    },

    historyButton: {
      title: 'Video history',
    },

    supportButton: {
      title: 'Support',
    },

    settingsButton: {
      title: 'Settings',
    },

    action: {
      slowNetwork:
        'Oops! Your network seems slow. Please ensure a stable, fast connection and refresh the page to check if the issue persists.',
      noVideosLeft: 'No videos left...',
      noFreeVideosLeft: 'No free videos left...',
      btn: 'Summarize and chat',
      comeback: 'Come back at 12 a.m. Pacific Time tomorrow.',
      upgrade: 'Or upgrade now for instant access to unlimited daily videos 🚀',
      process: 'Process this video',
      expectLengthy: 'Expect a lengthy waiting time.',
      average: 'Average waiting time (in minutes):',
      wait: '*This is a video without transcripts. Please wait for additional processes to be completed.',
      live: 'Live',
      private: 'Private',
      liveNotSupported: 'Live video is not supported',
      privateNotSupported: 'Private video is not supported',
      freeLimit: 'Free plan is limited to videos without transcripts less than 15 minutes',
      proLimit: 'Pro plan is limited to videos without transcripts less than 1 hour',
      growthLimit: 'Growth plan is limited to videos without transcripts less than 45 minutes',
      essentialLimit:
        'Essential plan is limited to videos without transcripts less than 30 minutes',
      unavailable: 'Unavailable',
      upgradeText: 'Upgrade',
      retry: 'Retry',
      ready: 'This video is ready to chat',
      gettingReady: "We're getting ready for your chat...",
      currentStatus: 'Current status:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Loading',
      PREPARING: 'Preparing',
      THINKING: 'Thinking',
      GENERATING_QUESTIONS: 'Generating questions',
      ANALYZING_DATA: 'Analyzing data',
      FINALIZING_REPLY: 'Finalizing reply',
      EVALUATING_POSSIBLE_SOLUTION: 'Evaluating possible solutions',
    },
    briefSummary: 'Brief summary',
    retry: 'Retry',
    mightAsk: 'You might want to ask...',
    transcripts: 'Transcripts',
    subscribe: 'Subscribe',
    copied: 'Copied',
    copyConversation: 'Copy conversation',
    loading: 'Loading...',
    copyShare: 'Copy link and share',
    messageLeft: 'Messages left:',
    writeQuestion: 'Write a question...',
    copy: 'Copy',
  },

  modal: {
    history: {
      title: 'Video history',
      loading: 'Loading...',
      reachEnd: "You've reached the end",
      noHistory: 'You have no video history',
      searchPlaceholder: 'Search video history...',
      noMatch: "It looks like there aren't many great matches for your search",
    },

    premium: {
      cancelled: 'Subscription is cancelled',
      nextPayment: 'Next payment on',
      pendingOrder:
        "We've noticed that your order is still pending. Please proceed to finalize your payment.",
      paymentComplete: 'Payment Completed?',
      askComplete: 'Have you been redirected to the new page and completed your payment?',
      confirm: 'Confirm',
      cancel: 'Cancel',
      benefitTitle: 'With this plan, you receive the following benefits:',
      subscribe: 'Subscribe',

      plans: {
        essential: {
          video: '30 videos per day',
          message: '30 messages per video',
          transcript_free: 'Process videos without transcripts (up to 30 minutes each)',
          desc: 'Perfect for moderate usage, offering substantial daily access to video chats.',
        },
        growth: {
          video: '100 videos per day',
          message: '100 messages per video',
          transcript_free: 'Process videos without transcripts (up to 45 minutes each)',
          desc: 'Unlock greater possibilities with expanded video chats and messaging capabilities.',
        },
        pro: {
          video: 'Unlimited videos per day',
          message: 'Unlimited messages per video',
          transcript_free: 'Process videos without transcripts (up to 1 hour each)',
          desc: 'Open the door to infinite interactions with all-day, every-video chat access.',
        },
      },

      prioritySupport: 'Priority email support',
      priorityAccess: 'Priority access to new features',
      monthly: 'Monthly',
      yearly: 'Yearly',
      manageSubscription: 'Manage your subscription here',
      needSupport: 'Need support? Contact:',
      mostPopular: 'Most popular',
      notSatisfied: 'Not satisfied? No worries.',
      offerRefund: 'We provide a refund within 14 days of purchase.',
      yourPlan: 'Your Plan',
      upgradeYourPlan: 'Upgrade Your Plan',
    },

    settings: {
      title: 'Settings',
      signedInAs: 'Signed in as',
      email: 'Email',
      language: 'Language',
      subscribe: 'Subscribe',
      signOut: 'Sign out',
    },
  },

  promptSuggestions: {
    turnOff: 'Turn off prompt suggestions',
    title: 'Prompt suggestions',
    description: 'Enabling it will provide prompt suggestions after each response.',
  },
}
