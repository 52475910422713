export const pt = {
  error: {
    unknown:
      'Ocorreu um erro. Por favor, tente novamente. Entre em contato com o suporte se o problema persistir.',
    errMsg: {
      DONE: 'Ocorreu um erro. Atualize a página ou entre em contato com o suporte se o problema persistir.',
      RETRY: 'Ocorreu um erro.',
      TOKEN_LIMIT: 'Limite de tokens atingido.',
    },
  },

  header: {
    withoutAuth: {
      btn: 'Faça login para conversar com este vídeo',
    },

    videoCountButton: {
      noVideosLeft: 'Não há mais vídeos; volte às 12h do horário do Pacífico amanhã.',
      noFreeVideosLeft: 'Não há mais vídeos gratuitos; volte às 12h do horário do Pacífico amanhã.',
      videosRemainingToday: 'Vídeos restantes hoje:',
      upgradePlan: '(Clique para atualizar seu plano)',
      getMore: '(Clique para obter mais)',
    },

    historyButton: {
      title: 'Histórico de vídeos',
    },

    supportButton: {
      title: 'Suporte',
    },

    settingsButton: {
      title: 'Configurações',
    },

    action: {
      slowNetwork:
        'Ops! Sua rede parece estar lenta. Por favor, certifique-se de ter uma conexão estável e rápida e atualize a página para verificar se o problema persiste.',
      noVideosLeft: 'Não há mais vídeos...',
      noFreeVideosLeft: 'Não há mais vídeos gratuitos...',
      btn: 'Resumir e conversar',
      comeback: 'Volte às 12h do horário do Pacífico amanhã.',
      upgrade: 'Ou atualize agora para acesso instantâneo a vídeos diários ilimitados 🚀',
      process: 'Processar este vídeo',
      expectLengthy: 'Espere um tempo de espera longo.',
      average: 'Tempo médio de espera (em minutos):',
      wait: '*Este é um vídeo sem transcrições. Aguarde a conclusão de processos adicionais.',
      live: 'Ao vivo',
      private: 'Privado',
      liveNotSupported: 'Vídeo ao vivo não é suportado',
      privateNotSupported: 'Vídeo privado não é suportado',
      freeLimit: 'O plano gratuito é limitado a vídeos sem transcrições com menos de 15 minutos',
      proLimit: 'O plano Pro é limitado a vídeos sem transcrições com menos de 1 hora',
      growthLimit: 'O plano Growth é limitado a vídeos sem transcrições com menos de 45 minutos',
      essentialLimit:
        'O plano Essential é limitado a vídeos sem transcrições com menos de 30 minutos',
      unavailable: 'Indisponível',
      upgradeText: 'Atualizar',
      retry: 'Tentar novamente',
      ready: 'Este vídeo está pronto para conversar',
      gettingReady: 'Estamos nos preparando para o seu chat...',
      currentStatus: 'Status atual:',
    },
  },

  chatArea: {
    loadingState: {
      LOADING: 'Carregando',
      PREPARING: 'Preparando',
      THINKING: 'Pensando',
      GENERATING_QUESTIONS: 'Gerando perguntas',
      ANALYZING_DATA: 'Analisando dados',
      FINALIZING_REPLY: 'Finalizando resposta',
      EVALUATING_POSSIBLE_SOLUTION: 'Avaliando possíveis soluções',
    },
    briefSummary: 'Resumo breve',
    retry: 'Tentar novamente',
    mightAsk: 'Você pode querer perguntar...',
    transcripts: 'Transcrições',
    subscribe: 'Inscrever-se',
    copied: 'Copiado',
    copyConversation: 'Copiar conversa',
    loading: 'Carregando...',
    copyShare: 'Copiar link e compartilhar',
    messageLeft: 'Mensagens restantes:',
    writeQuestion: 'Escreva uma pergunta...',
    copy: 'Copiar',
  },

  modal: {
    history: {
      title: 'Histórico de vídeos',
      loading: 'Carregando...',
      reachEnd: 'Você chegou ao fim',
      noHistory: 'Você não possui histórico de vídeos',
      searchPlaceholder: 'Pesquisar histórico de vídeos...',
      noMatch: 'Parece que não há muitas correspondências para a sua pesquisa',
    },

    premium: {
      cancelled: 'A assinatura foi cancelada',
      nextPayment: 'Próximo pagamento em',
      pendingOrder:
        'Observamos que seu pedido ainda está pendente. Por favor, prossiga para finalizar seu pagamento.',
      paymentComplete: 'Pagamento concluído?',
      askComplete: 'Você foi redirecionado para a nova página e concluiu seu pagamento?',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      benefitTitle: 'Com este plano, você recebe os seguintes benefícios:',
      subscribe: 'Inscrever-se',

      plans: {
        essential: {
          video: '30 vídeos por dia',
          message: '30 mensagens por vídeo',
          transcript_free: 'Processar vídeos sem transcrições (até 30 minutos cada)',
          desc: 'Perfeito para uso moderado, oferecendo acesso diário substancial a chats de vídeo.',
        },
        growth: {
          video: '100 vídeos por dia',
          message: '100 mensagens por vídeo',
          transcript_free: 'Processar vídeos sem transcrições (até 45 minutos cada)',
          desc: 'Desbloqueie maiores possibilidades com chats de vídeo expandidos e capacidades de mensagens.',
        },
        pro: {
          video: 'Vídeos ilimitados por dia',
          message: 'Mensagens ilimitadas por vídeo',
          transcript_free: 'Processar vídeos sem transcrições (até 1 hora cada)',
          desc: 'Abra as portas para interações infinitas com acesso a chats o dia todo, todos os dias.',
        },
      },

      prioritySupport: 'Suporte por e-mail prioritário',
      priorityAccess: 'Acesso prioritário a novos recursos',
      monthly: 'Mensal',
      yearly: 'Anualmente',
      manageSubscription: 'Gerencie sua assinatura aqui',
      needSupport: 'Precisa de suporte? Entre em contato com:',
      mostPopular: 'Mais popular',
      notSatisfied: 'Não satisfeito? Sem problemas.',
      offerRefund: 'Oferecemos reembolso em até 14 dias após a compra.',
      yourPlan: 'Seu Plano',
      upgradeYourPlan: 'Atualize seu Plano',
    },

    settings: {
      title: 'Configurações',
      signedInAs: 'Conectado como',
      email: 'E-mail',
      language: 'Idioma',
      subscribe: 'Inscrever-se',
      signOut: 'Sair',
    },
  },

  promptSuggestions: {
    turnOff: 'Desativar sugestões',
    title: 'Sugestões de prompt',
    description: 'Ao ativá-lo, serão fornecidas sugestões após cada resposta',
  },
}
