import {DOMAIN} from './common'

export const DEV = true

export const WEB = process.env.web

// export const url = 'http://localhost:3000'
export const url = DEV ? DOMAIN.CHATTUBE.DEV : DOMAIN.CHATTUBE.PROD

export const OFFICIAL_WEBSITE = 'https://chattube.io'

export const SUPPORT_MAIL = 'support@chattube.io'

export const SUPPORT_FORM = url + '/form/support'
export const FEEDBACK_FORM = url + '/form/feedback'

export const CHROME_WEB_STORE =
  'https://chrome.google.com/webstore/detail/chattube-chat-with-any-yo/folgjbfgejcliggchmhbbbmdanjjpnai'

export const FIREBASE_CONFIG = {
  DEV: {
    apiKey: 'AIzaSyA_FWlARwu-fDYnfxLZ8An4XlA0V46qXXc',
    authDomain: 'auth-develop.chattube.io',
    projectId: 'chattube-dev',
    storageBucket: 'chattube-dev.appspot.com',
    messagingSenderId: '921305907549',
    appId: '1:921305907549:web:3590e2bb18b58d3374a8b4',
  },
  PROD: {
    apiKey: 'AIzaSyBqWc9Yp5xFuxyQvy1nMJOYTC8eryLvmqM',
    authDomain: 'auth.chattube.io',
    projectId: 'chattube-5736a',
    storageBucket: 'chattube-5736a.appspot.com',
    messagingSenderId: '864061090456',
    appId: '1:864061090456:web:f3494736bb7a0c39c43eed',
  },
}
