export const appName = 'chattube-chrome-extension'
export const containerName = 'chattube-container'

export const CONTEXT_KEY = {
  MODE: 'MODE',
  COLLAPSIBLE: 'COLLAPSIBLE',
  CHATS: 'CHATS',
  LAST_CHAT: 'LAST_CHAT',
  VIDEO_INFO: 'VIDEO_INFO',
  CURRENT_USER: 'CURRENT_USER',
  LANGUAGE: 'LANGUAGE',
  PREMIUM_MODAL_VISIBLE: 'PREMIUM_MODAL_VISIBLE',
}

export const CHROME_STORAGE_KEY = {
  ID_TOKEN: 'ID_TOKEN',
}

export const MODE = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
}

export const LANGUAGE_CODE = {
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  PT: 'pt',
  RU: 'ru',
  ZH: 'zh',
  AR: 'ar',
  PL: 'pl',
}

export const LANGUAGE_IN_FULL = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  pt: 'Portuguese',
  ru: 'Russian',
  zh: 'Chinese',
  ar: 'Arabic',
  pl: 'Polish',
}

export const MESSAGE_CODE = {
  TOKEN: 'TOKEN', // 通过 chattube.io 登录获取 token
  SEND_AUTH_TOKEN: 'SEND_AUTH_TOKEN', // 获取 token 后，发给 content script 再次登录

  OPEN_URL: 'OPEN_URL',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_EXTERNAL: 'SIGN_OUT_EXTERNAL',
  CAPTIONS: 'CAPTIONS',
  CREATE_CHAT_RECORD: 'CREATE_CHAT_RECORD',
  GET_LAST_CHAT: 'GET_LAST_CHAT',
  RESTORE_CHAT: 'RESTORE_CHAT',
  GET_ID_TOKEN: 'GET_ID_TOKEN',
  CHECKOUT: 'CHECKOUT',
  LAST_SUBSCRIPTION: 'LAST_SUBSCRIPTION',
  REQUEST_PROCESS_TRANSCRIPT: 'REQUEST_PROCESS_TRANSCRIPT',
  RETRY_PROCESS_TRANSCRIPT: 'RETRY_PROCESS_TRANSCRIPT',
  GET_TRANSCRIPT_QUEUE: 'GET_TRANSCRIPT_QUEUE',
  GET_TRANSCRIPT_QUEUE_BY_ID: 'GET_TRANSCRIPT_QUEUE_BY_ID',
  GET_TRANSCRIPT_QUEUE_LIST: 'GET_TRANSCRIPT_QUEUE_LIST',
  GET_TRANSCRIPT: 'GET_TRANSCRIPT',
  UPDATE_CHAT_LANGUAGE: 'UPDATE_CHAT_LANGUAGE',
}

export const DOMAIN = {
  CHATTUBE: {
    PROD: 'https://chattube.io',
    DEV: 'https://develop.chattube.io',
    LOCAL: 'http://localhost:3000',
  },
}

export const CHATTUBE_ROUTE = {
  SIGN_IN: '/sign-in',
}

export const ROLE = {
  SYSTEM: 'system' as const,
  USER: 'user' as const,
  ASSISTANT: 'assistant' as const,
}

export const WELCOME_MESSAGE = [
  {
    en: 'Hey! Ready to learn more about this video?',
    es: '¡Hola! ¿Listo para aprender más sobre este video?',
    fr: 'Salut! Prêt à en savoir plus sur cette vidéo?',
    de: 'Hallo! Bereit, mehr über dieses Video zu erfahren?',
    it: 'Ehi! Pronto a saperne di più su questo video?',
    ja: 'ねえ！このビデオについてもっと学びたいですか？',
    ko: '헤이! 이 비디오에 대해 더 배우실 준비가 되셨나요?',
    pt: 'Oi! Pronto para aprender mais sobre este vídeo?',
    ru: 'Привет! Готовы узнать больше об этом видео?',
    zh: '嘿！准备好了解更多关于这个视频的信息吗？',
    ar: 'مرحبا! هل أنت مستعد لمعرفة المزيد عن هذا الفيديو؟',
    pl: 'Hej! Gotowy dowiedzieć się więcej o tym filmie?',
  },
  {
    en: 'Hi there! Got any questions about this video?',
    es: '¡Hola! ¿Tienes alguna pregunta sobre este video?',
    fr: 'Salut là-bas! Avez-vous des questions sur cette vidéo?',
    de: 'Hallo! Haben Sie Fragen zu diesem Video?',
    it: 'Ciao! Hai delle domande su questo video?',
    ja: 'こんにちは！このビデオについて質問はありますか？',
    ko: '안녕하세요! 이 비디오에 대해 궁금한 점이 있나요?',
    pt: 'Oi! Tem alguma pergunta sobre este vídeo?',
    ru: 'Привет! Есть вопросы по этому видео?',
    zh: '你好！对这个视频有任何问题吗？',
    ar: 'مرحبا! هل لديك أي أسئلة حول هذا الفيديو؟',
    pl: 'Cześć! Masz jakieś pytania na temat tego filmu?',
  },
  {
    en: "Hello! Need more info about what you're watching?",
    es: '¡Hola! ¿Necesitas más información sobre lo que estás viendo?',
    fr: "Bonjour! Besoin de plus d'infos sur ce que vous regardez?",
    de: 'Hallo! Brauchen Sie mehr Informationen über das, was Sie sehen?',
    it: 'Ciao! Hai bisogno di più informazioni su quello che stai guardando?',
    ja: 'こんにちは！ご覧になっているものについて詳しく知りたいですか？',
    ko: '안녕하세요! 지금 보고 계신 것에 대해 더 많은 정보가 필요하신가요?',
    pt: 'Olá! Precisa de mais informações sobre o que você está assistindo?',
    ru: 'Привет! Нужна дополнительная информация о том, что вы смотрите?',
    zh: '你好！需要更多关于你正在观看的内容的信息吗？',
    ar: 'مرحبا! هل تحتاج إلى مزيد من المعلومات حول ما تشاهده؟',
    pl: 'Cześć! Potrzebujesz więcej informacji o tym, co oglądasz?',
  },
  {
    en: 'Hey! Wondering about something in the video?',
    es: '¡Hola! ¿Preguntándote sobre algo en el video?',
    fr: 'Hé! Vous vous demandez quelque chose dans la vidéo?',
    de: 'Hey! Fragen Sie sich etwas im Video?',
    it: 'Ehi! Ti stai chiedendo qualcosa nel video?',
    ja: 'ねえ！ビデオの中で何か疑問に思っていますか？',
    ko: '헤이! 비디오에 대해 궁금한 점이 있나요?',
    pt: 'Ei! Está se perguntando sobre algo no vídeo?',
    ru: 'Привет! Интересуетесь чем-то в видео?',
    zh: '嘿！对视频中的某些内容感到好奇吗？',
    ar: 'مرحبا! هل تتساءل عن شيء ما في الفيديو؟',
    pl: 'Hej! Zastanawiasz się nad czymś w filmie?',
  },
  {
    en: "Hello! Want to discuss this video's content?",
    es: '¡Hola! ¿Quieres discutir el contenido de este video?',
    fr: 'Bonjour! Vous voulez discuter du contenu de cette vidéo?',
    de: 'Hallo! Möchten Sie über den Inhalt dieses Videos diskutieren?',
    it: 'Ciao! Vuoi discutere del contenuto di questo video?',
    ja: 'こんにちは！このビデオの内容について話し合いたいですか？',
    ko: '안녕하세요! 이 비디오의 내용에 대해 토론하고 싶으신가요?',
    pt: 'Olá! Quer discutir o conteúdo deste vídeo?',
    ru: 'Привет! Хотите обсудить содержание этого видео?',
    zh: '你好！想要讨论这个视频的内容吗？',
    ar: 'مرحبا! هل ترغب في مناقشة محتوى هذا الفيديو؟',
    pl: 'Cześć! Chcesz omówić treść tego filmu?',
  },
  {
    en: 'Hi, and welcome! Care to know more about this video?',
    es: '¡Hola y bienvenido! ¿Te gustaría saber más sobre este video?',
    fr: 'Salut et bienvenue! Vous voulez en savoir plus sur cette vidéo?',
    de: 'Hallo und willkommen! Möchten Sie mehr über dieses Video erfahren?',
    it: 'Ciao e benvenuto! Vorresti saperne di più su questo video?',
    ja: 'こんにちは、ようこそ！このビデオについてもっと知りたいですか？',
    ko: '안녕하세요, 환영합니다! 이 비디오에 대해 더 알고 싶으신가요?',
    pt: 'Oi, e bem-vindo! Gostaria de saber mais sobre este vídeo?',
    ru: 'Привет, добро пожаловать! Хотите узнать больше об этом видео?',
    zh: '嗨，欢迎！想要了解更多关于这个视频的信息吗？',
    ar: 'مرحباً وأهلاً بك! هل ترغب في معرفة المزيد عن هذا الفيديو؟',
    pl: 'Witamy! Ciekawy, aby dowiedzieć się więcej o tym filmie?',
  },
]

export const SUMMARIZE_I18N = {
  en: 'Summarize',
  es: 'Resumir',
  fr: 'Résumer',
  de: 'Zusammenfassen',
  it: 'Riassumere',
  ja: '要約する',
  ko: '요약하다',
  pt: 'Resumir',
  ru: 'Резюмировать',
  zh: '总结',
  ar: 'يُلخص',
  pl: 'Podsumować',
}

export const SUMMARIZE_PROMPT_I18N = {
  en: 'Summarize the video into a list of key takeaways.',
  es: 'Resume el video en una lista de puntos clave.',
  fr: 'Résumez la vidéo en une liste de points clés.',
  de: 'Fassen Sie das Video in eine Liste von Schlüsselerkenntnissen zusammen.',
  it: 'Riassumi il video in un elenco di punti chiave.',
  ja: 'ビデオを要点のリストにまとめてください。',
  ko: '비디오를 핵심 포인트 목록으로 요약하십시오.',
  pt: 'Resuma o vídeo em uma lista de pontos-chave.',
  ru: 'Суммируйте видео в список ключевых моментов.',
  zh: '将视频总结为一份关键要点列表。',
  ar: 'قم بتلخيص الفيديو إلى قائمة من النقاط الرئيسية.',
  pl: 'Podsumować film w formie listy kluczowych wniosków.',
}

export const WELCOME_MESSAGE_I18N = WELCOME_MESSAGE.reduce(
  (final: {[key: string]: string[]}, item: {[key: string]: string}) => {
    const keys = Object.keys(item)

    for (const key of keys) {
      if (!final[key]) final[key] = []
      final[key].push(item[key])
    }

    return final
  },
  {}
)

export const PROGRESS_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  READY: 'READY',
  FAILED: 'FAILED',
}

export const TRANSCRIPT_PROCESSING_MIN: {[key: string]: number} = {
  '900': 2,
  '1800': 2,
  '2700': 3,
  '3600': 4,
}

export const PLAN = {
  ESSENTIAL: 'ChatTube Essential',
  GROWTH: 'ChatTube Growth',
  PRO: 'ChatTube Pro',
}

export const MODAL_ZINDEX = 99999
